import React, { useEffect } from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getContracts,
  getFilters,
} from "../../redux/actions/projectManagement";
import { getUserEntity } from "../../redux/actions/user";
import TablePaginationExecutive from "./components/TablePagination";

export default function Projects() {
  let history = useHistory();
  const user = useSelector((state) => state.user);
  const pm = useSelector((state) => state.projectManagement);

  const dispatch = useDispatch();
  if (!user?.user?.sub) {
    history.push("/login");
  }

  const handleEntity = (e) => {
    let find = user.entity.find(
      (elem) => elem.id_ente === parseInt(e.target.value)
    );
    dispatch({
      type: "SET_SELECTED_ENTITY",
      ente: parseInt(e.target.value),
      name: find.ente_name,
    });
    dispatch(getContracts(user.user.sub, e.target.value));
  };

  useEffect(() => {
    if (!user?.user) history.push("/login");
    else {
      dispatch({
        type: "SET_CONTRACT_DETAILS",
        payload: undefined,
        sp: undefined,
      });
      dispatch(getUserEntity(user.user.sub, user?.selectedEntity));
      console.log("this is pm in project management", pm);
      dispatch(getFilters());
    }
  }, []);

  return (
    <div className="container" style={{ paddingBottom: "18vh" }}>
      <div>
        <BreadcrumbConsip
          pages={[{ label: "Project Management", url: "/projects" }]}
        />
      </div>
      {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
      <Alert color="warning">idToken: {user.idToken}</Alert> */}
      <div className="pb-5">
        <div className="row text-left">
          <div className="col-12 col-md-5">
            <h4>Riepilogo delle tue informazioni</h4>
          </div>

          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div class="row">
                  <div className="col-12 col-md-3">
                    <img
                      alt="icon"
                      className="profile-image"
                      src={process.env.PUBLIC_URL + "/assets/icons/pm-user.svg"}
                    />
                  </div>
                  <div className="col-12 col-lg-9 profile-text">
                    <span>Responsabile:</span>
                    <p>
                      <b>{user?.user?.email}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div class="row">
                  <div className="col-12 col-md-3">
                    <img
                      alt="icon"
                      className="pm-entity-image"
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/pm-entity.svg"
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-9 profile-text">
                    <span>Ente Di Appartenenza:</span>
                    <br />
                    {user?.entity?.length !== 1 ? (
                      <select
                        className="w-100"
                        name="entity"
                        id="entity"
                        value={
                          user?.selectedEntity !== undefined
                            ? user?.selectedEntity
                            : user?.entity?.length > 0
                            ? user?.entity[0].id_ente
                            : ""
                        }
                        onChange={handleEntity}
                      >
                        {user?.entity.map((elem, index) => (
                          <option key={index} value={elem?.id_ente}>
                            {elem?.ente_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className="w-100" name="entity" id="entity">
                        {user?.entity[0].ente_name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3 px-2">
          <div
            className="col-12"
            style={{
              background: "linear-gradient(45deg,#06c,turquoise)",
              borderRadius: "15px",
            }}
          >
            <div className="row">
              <div className="col-6 align-self-center">
                <div
                  className="row align-items-center"
                  style={{ color: "white" }}
                >
                  <div className="col-6" style={{ fontSize: "38px" }}>
                    <b>
                      <img
                        alt="icon"
                        className="p-3"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/pm-initiatives-total.svg"
                        }
                      />
                      {pm?.contracts?.contractsArray?.length
                        ? pm?.contracts?.contractsArray?.length
                        : 0}
                    </b>
                  </div>
                  <div className="col-6 text-left">
                    <b>Numero Totale Contratti Esecutivi</b>
                  </div>
                </div>
              </div>
              <div
                className="col-6 text-left control py-3"
                style={{ color: "white" }}
              >
                <div>
                  Totale Budget:{" "}
                  {pm?.contracts?.budget !== undefined ? (
                    <b>€{pm?.contracts?.budget / 1000}K</b>
                  ) : (
                    <b>0K</b>
                  )}
                </div>
                <div>
                  Budget Speso:{" "}
                  {pm?.contracts?.usedBudget !== undefined ? (
                    <b>€{pm?.contracts?.usedBudget / 1000}K</b>
                  ) : (
                    <b>0K</b>
                  )}
                </div>
                <br></br>
                <ProgressBar
                  label={
                    pm?.contracts?.usedBudget !== undefined ? (
                      <b>€{pm?.contracts?.usedBudget / 1000}K</b>
                    ) : (
                      <b>0K</b>
                    )
                  }
                  style={{
                    borderRadius: "15px",
                    height: "50px",
                    border: "5px solid white",
                    marginRight: "2vw",
                  }}
                  min={0}
                  max={
                    pm?.contracts?.budget !== undefined
                      ? pm?.contracts?.budget / 1000
                      : 0
                  }
                  now={
                    pm?.contracts?.usedBudget !== undefined
                      ? pm?.contracts?.usedBudget / 1000
                      : 0
                  }
                />
              </div>
            </div>
            <div className="row" style={{ borderTop: "2px solid white" }}>
              <div className="col-6" style={{ borderRight: "2px solid white" }}>
                <div
                  className="row align-items-center"
                  style={{ color: "white" }}
                >
                  <div className="col-6">
                    <b style={{ fontSize: "38px" }}>
                      <img
                        alt="icon"
                        className="p-3"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/pm-initiatives-in-progress.svg"
                        }
                      />
                      {pm?.contracts?.startedContract
                        ? pm?.contracts?.startedContract
                        : 0}
                    </b>
                  </div>

                  <div className="col-6 text-left">
                    <b>Contratti Esecutivi Avviati</b>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="row align-items-center"
                  style={{ color: "white" }}
                >
                  <div className="col-6" style={{ fontSize: "24px" }}>
                    <b style={{ fontSize: "38px" }}>
                      <img
                        alt="icon"
                        className="p-3"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/pm-inititives-finished.svg"
                        }
                      />
                      {pm?.contracts?.endedContract
                        ? pm?.contracts?.endedContract
                        : 0}
                    </b>
                  </div>
                  <div className="col-6text-left">
                    <b>Contratti Esecutivi Conclusi</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-left">
          <div className="col-12">
            <h4>Riepilogo Contratti Esecutivo AQ</h4>
          </div>
          <div className="col-12">
            <div className="row mx-0">
              <div
                className="col-12 w-100"
                style={{
                  backgroundColor: "#e6e9f2",
                  borderRadius: "5px",
                }}
              >
                {pm?.contracts?.contractsArray?.length > 0 && (
                  <TablePaginationExecutive
                    data={pm?.contracts?.contractsArray}
                  ></TablePaginationExecutive>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
