import Collapse from "react-bootstrap/Collapse";
import {
  Icon,
  LinkList,
  LinkListItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
} from "design-react-kit";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import "./Components.css";
import { useSelector } from "react-redux";

export default function HeaderComponent() {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const user = useSelector((state) => state.user.user);

  return (
    <div>
      <div className="container d-block d-lg-none py-2 px-sm-1">
        <div className="text-left" tag="button">
          <Icon
            className="pointer"
            color="black"
            icon="it-burger"
            onClick={() => setActive(!active)}
            size=""
          />
        </div>
        <Collapse in={active}>
          <div className="menu-wrapper">
            <Nav
              className="row align-item-center justify-content-center"
              tag="ul"
              vertical={false}
            >
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/aq");
                  }}
                  tag="a"
                >
                  <span className="m-0">Accordo Quadro</span>
                  {/* <span className="sr-only">current</span> */}
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/service");
                  }}
                  tag="a"
                >
                  Servizi
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/come-aderire");
                  }}
                  tag="a"
                >
                  Come Aderire
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/news");
                  }}
                  tag="a"
                >
                  News
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/faq");
                  }}
                  tag="a"
                >
                  Domande Frequenti
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                <NavLink
                  onClick={() => {
                    setActive(false);
                    history.push("/chi-siamo");
                  }}
                  tag="a"
                >
                  Chi Siamo
                </NavLink>
              </NavItem>
              <NavItem className="pointer col-12" tag="li">
                {user === undefined || user === null ? (
                  <NavLink onClick={() => history.push("/login")} tag="a">
                    Area Riservata
                  </NavLink>
                ) : (
                  <UncontrolledDropdown nav tag="div">
                    <DropdownToggle
                      className="color-nav-md"
                      aria-haspopup
                      caret
                      nav
                    >
                      Area Riservata
                      {/* <Icon color="" icon="it-expand" padding={false} size="" /> */}
                    </DropdownToggle>
                    <DropdownMenu className="w-100 text-center" flip tag="div">
                      <LinkList tag="div">
                        <LinkListItem
                          onClick={() => {
                            setActive(false);
                            history.push("/info");
                          }}
                        >
                          <span className="m-0">Area Informativa</span>
                        </LinkListItem>
                        <LinkListItem
                          onClick={() => {
                            setActive(false);
                            history.push("/projects");
                          }}
                        >
                          <span className="m-0">Project Management</span>
                        </LinkListItem>

                        <LinkListItem onClick={() => {
                            setActive(false);
                            history.push("/monitoring");
                          }}>
                          <span className="m-0">Monitoraggio</span>
                        </LinkListItem>
                        <LinkListItem onClick={() => {
                            setActive(false);
                            history.push("/observatory");
                          }}>
                          <span className="m-0">Osservatorio</span>
                        </LinkListItem>

                        {/*
            <LinkListItem divider tag="a" />
            <LinkListItem href="#">
              <span className="m-0">Informazioni</span>
            </LinkListItem>
            */}
                      </LinkList>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </NavItem>
            </Nav>
          </div>
        </Collapse>
      </div>
      <div className="container d-none d-lg-block">
        <div className="menu-wrapper" style={{ color: "white !important" }}>
          <Nav className="d-flex flex-row color-nav" tag="ul" vertical={false}>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/aq")} tag="a">
                <span className="m-0">Accordo Quadro</span>
                {/* <span className="sr-only">current</span> */}
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/service")} tag="a">
                Servizi
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/come-aderire")} tag="a">
                Come Aderire
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/news")} tag="a">
                News
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/faq")} tag="a">
                Domande Frequenti
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              <NavLink onClick={() => history.push("/chi-siamo")} tag="a">
                Chi Siamo
              </NavLink>
            </NavItem>
            <NavItem className="flex-nav pointer" tag="li">
              {user === undefined || user === null ? (
                <NavLink onClick={() => history.push("/login")} tag="a">
                  Area Riservata
                </NavLink>
              ) : (
                <UncontrolledDropdown nav tag="div">
                  <DropdownToggle
                    className="color-nav"
                    aria-haspopup
                    caret
                    color="white"
                    nav
                  >
                    Area Riservata
                    {/* <Icon color="" icon="it-expand" padding={false} size="" /> */}
                  </DropdownToggle>
                  <DropdownMenu className="left-drop" flip tag="div">
                    <LinkList tag="div">
                      <LinkListItem onClick={() => history.push("/info")}>
                        <span className="m-0">Area Informativa</span>
                      </LinkListItem>
                      <LinkListItem onClick={() => history.push("/projects")}>
                        <span className="m-0">Project Management</span>
                      </LinkListItem>

                      <LinkListItem onClick={() => history.push("/monitoring")}>
                        <span className="m-0">Monitoraggio</span>
                      </LinkListItem>
                      <LinkListItem onClick={() => history.push("/observatory")}>
                        <span className="m-0">Osservatorio</span>
                      </LinkListItem>

                      {/*
            <LinkListItem divider tag="a" />
            <LinkListItem href="#">
              <span className="m-0">Informazioni</span>
            </LinkListItem>
            */}
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}{" "}
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  );
}
