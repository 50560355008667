import {
  Card,
  CardCategory,
  CardReadMore,
  CardSignature,
  Icon,
} from "design-react-kit";
import React from "react";
import { CardBody, CardText, CardTitle } from "reactstrap";

export const InfoCard = ({ title, icon, caption, url, cardClass }) => {
  return (
    <Card className={cardClass} noWrapper={false} tag="div">
      <CardBody tag="div">
        <CardTitle className="big-heading" tag="h6">
          {title}
        </CardTitle>
        <img className="py-4" height={142} src={icon} alt="icon" />
        <CardText tag="p" className="text-left">
          {caption}
        </CardText>
        <div>
          <a
            style={{ textDecoration: "none", paddingLeft: "2vw" }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: 700,
                textTransform: "uppercase",
                letterApacing: ".9px",
              }}
            >
              {title === "Guida alla stima" ? (
                <b>SCARICA LO STRUMENTO DI SIMULAZIONE</b>
              ) : (
                <b>VEDI I DETTAGLI</b>
              )}
            </span>
            <Icon icon="it-arrow-right" padding={true} color="primary" />
          </a>
        </div>
        {/*<CardReadMore
          iconName="it-arrow-right"
          tag="a"
          text="Vedi i dettagli"
          href={url}
        />*/}
      </CardBody>
    </Card>
  );
};
export const InfoCardBottom = ({ title, icon, caption, url, cardClass }) => {
  return (
    <Card className={cardClass} noWrapper={false} tag="div">
      <CardBody tag="div">
        <img className="py-4" src={icon} alt="icon" />
        <CardTitle className="big-heading" tag="h6">
          {title}
        </CardTitle>
        <CardText tag="p" className="text-left">
          {caption}
        </CardText>
        <CardReadMore
          iconName="it-arrow-right"
          tag="a"
          text="Vedi i dettagli"
          href={url}
        />
      </CardBody>
    </Card>
  );
};
