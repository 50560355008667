import Collapse from "react-bootstrap/Collapse";
import { Button } from "design-react-kit";
import React, { useState } from "react";
import { apiCurrentSession } from "../services/apiRequests";

export const AccordionConsip = ({ data, section }) => {
  //console.log(data)
  const [active, setActive] = useState(-1);
  const changeActive = (id) => {
    if (active !== id) setActive(id);
    else setActive(-1);
  };

  // let newLink = (elem) => {
  //   console.log(elem)
  //   if (elem == 28) {
  //     return window.open('https://bugblast.sicurezzapal-lotto2.it/login/cognito?access_token=eyJraWQiOiJiOFh6NGs1TmoxN2pGTVwvZkt4amx5dURGYkU3TSsxNWFvUDhab3NVcjVFVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YTVmYzFmMi05M2IyLTQzMzctYTE0MC1hMDA2MWVlNDgxZmEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9yWEpXUzFTREgiLCJjbGllbnRfaWQiOiIzaGgzaXBzNHM4dmJlcmk5dmxmOGdoZHVncyIsIm9yaWdpbl9qdGkiOiI4YjE2ZGI4OC04Zjg1LTQ3NTUtODEyMi05ZWUyYzhkMWE2MjQiLCJldmVudF9pZCI6Ijg0Njk2N2ZjLTQxN2ItNDVkNy04ZGM5LTc1MDkxZDYzZmQ2NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NTcxNzk0NjgsImV4cCI6MTY1NzUyNDE3MywiaWF0IjoxNjU3NTIwNTczLCJqdGkiOiI2NTI5MWVjYi1hZTZkLTQ1MjMtYjEzYi0zYTIwNDVmOTk0MjciLCJ1c2VybmFtZSI6ImlrYXBhIn0.NOMnm6u6PL6EwGpwt3Ho5YrfInmrUOh0nBIHO9coi3aoWG06iZ8Z1zPkRCcJTPcTyPmwE-K9RWFja2zAy1e91cUH6VONMhZ4kiGQ-SdXXOv3m6iUdxGaZnGZ5tlCvevfXzRh35ucW_jXNTdvGPt3PFairtk3AbyvWuK1cVbfqO5_jXxCl3BqtGb5_3NxgG6OxeK4srJXdn-9LyguSlATtVqTNvUWgnPae6E_3-Wv2R74r8zzjqtGFrCdV2uPtzIH7NG-wGCj322viRw7g-fTPpdpo-5rnf_JGTR4yPC2TbtVI-g2YrFbxqxUUD1SIf4H8DGRPfh4uGXtHnLeNOJiQw', '_blank')

  //   }
  //   return changeActive(elem)
  // }

  let newLink = (elem) => {
    console.log(elem);
    if (elem == 28) {
      apiCurrentSession()
        .then((response) => {
          let token = response.getAccessToken().getJwtToken();
          let url = 'https://bugblast.sicurezzapal-lotto2.it/login/cognito?access_token=' + token;
          window.open(url, '_blank');
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      changeActive(elem);
    }
  };
  
  return (
    <div>
      {data?.length > 0 &&
        data.map((elem, index) => (
          <div key={"accordion" + index}>
            {index === 0 ? <h6>{elem?.reference}</h6> : ""}

            <Button
              onFocus={() => { }}
              className="text-left"
              color="secondary"
              style={
                active === elem?.id
                  ? { boxShadow: "none" }
                  : { boxShadow: "none", border: "1px solid #e6e9f2" }
              }
              outline={active !== elem?.id}
              block
              aria-controls={elem?.id}
              aria-expanded={active === elem?.id}
              onClick={() => newLink(elem?.id)}
              tag="button"
            >
              {elem?.title}
            </Button>
            <Collapse in={active === elem?.id}>
              <div className="px-4" id={elem?.id}>
                <p>{elem?.bodyStart}</p>
                {elem?.bodyBulletPoint?.length > 0 && (
                  <ul>
                    {elem.bodyBulletPoint.map((text, index) => (
                      <li key={"li" + index}>{text}</li>
                    ))}
                  </ul>
                )}
                <p>{elem?.bodyEnd}</p>
                {elem?.links?.length > 0 &&
                  elem.links.map((value, index) => (
                    <div class="row">
                      <div class="col-12 col-lg-1">
                        <div
                          class="container"
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20 / 2,
                            backgroundColor: value?.status_color,
                            border: "2px solid black",
                            position: "absolute",
                          }}
                        ></div>
                      </div>

                      <div class="col-12 col-lg-11">
                        <p key={index}>
                          {value?.textLink}
                          <a
                            href={value?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {value?.nameLink !== "" &&
                              value?.nameLink !== undefined
                              ? value.nameLink
                              : value.link}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </Collapse>
          </div>
        ))}
    </div>
  );
};
