/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "consipsicurezzapal-20220222133410-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3mlup7xsg7bho.cloudfront.net",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Z1FGiu1RQ",
    "aws_user_pools_web_client_id": "1b9p1nkhgekp3ivg757s8kc291",
    "oauth": {
        "domain": "sicurezzapal-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://sicurezzapal-prod.auth.eu-west-1.amazoncognito.com",
        "redirectSignOut": "https://sicurezzapal-prod.auth.eu-west-1.amazoncognito.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
