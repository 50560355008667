import { BreadcrumbConsip } from "../../../../components/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../../style.css";
//import TablePaginationExecutive from "../../components/TablePagination";
import { useHistory } from "react-router-dom";
import { App } from "./chart";

export default function CustomerSatisfaction() {
  let history = useHistory();

  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[
            {
              label: "/Monitoring/Customer Satisfaction",
              url: "/monitoring/customer-satisfaction",
            },
          ]}
        />
      </div>

      {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
         <Alert color="warning">idToken: {user.idToken}</Alert> */}
      <div className="info-gradient pb-5">
        <div className="container" style={{ paddingBottom: "18vh" }}>
          <div className="component-holder">
            <div className="row text-left">
              <div className="col-12 col-md-5">
                <h4>Customer satisfaction e qualità delle informazioni</h4>
              </div>
            </div>
            <div className="title_description">
              <p>Title Description</p>
            </div>
          </div>
          <div className="chart_background">
            <canvas id="radarChart" height="20"></canvas>
            <App />
          </div>

          <br></br>
          <br></br>

          <div className="row my-3 px-2">
            <div
              className="col-12"
              style={{
                background: "#d4d4d4",
                borderRadius: "15px",
              }}
            >
              <div className="row">
                <div className="col-6 align-self-center">
                  <div
                    className="row align-items-center"
                    style={{ color: "black" }}
                  >
                    <div className="col-6" style={{ fontSize: "20px" }}>
                      <b>
                        N. CE con eccezioni alla qualità e sicurezza delle
                        informazioni
                      </b>
                    </div>
                  </div>
                </div>
                <div
                  className="col-6 text-left control py-3"
                  style={{ color: "white" }}
                >
                  <ProgressBar
                    style={{
                      borderRadius: "15px",
                      height: "30px",
                      border: "2px solid white",
                    }}
                    min={0}
                    max={100}
                    now={56}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row text-left">
                        <div className="col-12">
                            <h4>Riepilogo Contratti Esecutivo AQ</h4>
                        </div>
                        <div className="col-12">
                            <div className="row mx-0">
                                <div
                                    className="col-12 w-100"
                                    style={{
                                        backgroundColor: "#e6e9f2",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <TablePaginationExecutive>data=""</TablePaginationExecutive>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
