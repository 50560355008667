import { BreadcrumbConsip } from "../../../../components/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IndexCard, IndexCardBottom } from "./IndexCard";
import "../../style.css";
import TablePaginationExecutive from "../../../ProjectManagement/components/TablePagination";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilters,
  getDigIndexes,
} from "../../../../redux/actions/projectManagement";

export default function DigitalizationIndexes() {
  let history = useHistory();
  const dispatch = useDispatch();
  const pm = useSelector((state) => state.projectManagement);

  useEffect(() => {
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    dispatch(getDigIndexes(filters));
  }, []);

  //filters
  const [enteType, setEnteType] = useState([]);
  const [enteTypeId, setEnteTypeId] = useState("");
  const [ente, setEnte] = useState([]);
  const [enteId, setEnteId] = useState("");
  const [contract, setContract] = useState([]);
  const [contractId, setContractId] = useState("");
  const [filters, setFilters] = useState({});

  const applyFilters = () => {
    let filtersnew = {};

    if (enteTypeId) {
      filtersnew.enteType = parseInt(enteTypeId);
    }
    if (enteId) {
      filtersnew.enti = parseInt(enteId);
    }
    if (contractId) {
      filtersnew.contrattiEsecutivi = parseInt(contractId);
    }
    setFilters(filtersnew);
    dispatch(getDigIndexes(filtersnew));
  };

  useEffect(() => {
    const getEnteType = async () => {
      setEnteType(await pm?.filters?.filterArray?.allFilters);
    };
    getEnteType();
  }, []);

  useEffect(() => {
    const getEnte = async () => {
      enteType.map((i) => {
        if (i.ente_type_id == enteTypeId) {
          setEnte(i.Entis);
        }
      });
      if (!enteTypeId) {
        setEnte([]);
      }
    };
    setEnte([]);
    setEnteId("");
    getEnte();
    document.getElementById("ente_select").selectedIndex = 0;
    setContract([]);
    setContractId("");
  }, [enteTypeId]);

  useEffect(() => {
    const getContract = async () => {
      ente.map((i) => {
        if (i.id_ente == enteId) {
          setContract(i.ContrattiEsecutivis);
        }
      });
    };
    getContract();
    setContractId("");
    document.getElementById("contract_select").selectedIndex = 0;
  }, [enteId]);

  /////////////////////////////////

  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[
            {
              label: "/Monitoring/Digitalization-Indexes",
              url: "/monitoring/digitalization-indexes",
            },
          ]}
        />
      </div>

      {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
         <Alert color="warning">idToken: {user.idToken}</Alert> */}
      <div className="info-gradient pb-5">
        <div className="container" style={{ paddingBottom: "12vh" }}>
          <form>
            <div className="row g-3">
              <div className="col-md-4" style={{ textAlign: "left" }}>
                <h4>Digitalization Indexes</h4>
                {/* <div className="title_description">
                                        <p>Title Description</p>
                                    </div> */}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label className="form-label">Tipologia Ente</label>
                <select
                  name="tipologia_ente"
                  className="form-control p-2"
                  onChange={(e) => setEnteTypeId(e.target.value)}
                >
                  <option value=""></option>
                  {enteType.map((resEnteType, index) => (
                    <option key={index} value={resEnteType.ente_type_id}>
                      {resEnteType.ente_type_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label">Ente</label>
                <select
                  id="ente_select"
                  className="form-control p-2"
                  name="ente"
                  onChange={(e) => setEnteId(e.target.value)}
                >
                  <option selected value=""></option>
                  {ente.map((test, index) => (
                    <option key={index} value={test.id_ente}>
                      {test.ente_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label">Contratto Esecutivo</label>
                <select
                  id="contract_select"
                  className="form-control p-2"
                  name="contratto"
                  onChange={(e) => setContractId(e.target.value)}
                >
                  <option selected value=""></option>
                  {contract.map((test2, index) => (
                    <option key={index} value={test2.id_contratto}>
                      {" "}
                      {test2.contratto_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <button
                type="button"
                className="btn btn-primary mt-1"
                style={{ paddingRight: "1vw", paddingLeft: "1vw" }}
                onClick={applyFilters}
              >
                Applica Filtri
              </button>
            </div>
          </form>
          <br></br>
          <br></br>
          {/* indexes */}
          {pm?.indexes?.response[0] !== undefined && (
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-12 col-md-5">
                <IndexCard
                  cardClass="card-bg rounded"
                  title={"Indicatori quantitativi"}
                  data1={pm?.indexes?.response[0]?.riduzione_spesa + "%"}
                  data2={pm?.indexes?.response[0]?.riduzione_tempi + "%"}
                  data3={pm?.indexes?.response[0]?.numero_servizi_aggiuntivi}
                  caption1="Riduzione % della spesa per l’erogazione del servizio"
                  caption2="Riduzione % dei tempi di erogazione del servizio"
                  caption3="Numero servizi aggiuntivi offerti all’utenza interna, esterna (cittadini), esterna (imprese), altre PA"
                  updateDate="23/5/2022"
                />
              </div>
              <br></br>
              <br></br>
              {/* <div className="col-md-1"></div> */}
              <div className="col-12 col-md-5">
                <IndexCard
                  cardClass="card-bg rounded"
                  title={"Indicatori qualitativi"}
                  data1={pm?.indexes?.response[0]?.obiettivi_cad}
                  data2={
                    pm?.indexes?.response[0]?.infrastrutture_immateriali + "%"
                  }
                  data3={pm?.indexes?.response[0]?.integrazioni_base_dati}
                  caption1="Obiettivi CAD raggiunti con l’intervento"
                  caption2="Infrastrutture immateriali integrate"
                  caption3="Integrazione con Basi Dati di interesse nazionale"
                  updateDate="23/5/2022"
                />
              </div>
            </div>
          )}
          <br></br>
          <br></br>
          {pm?.indexes?.response[0] !== undefined && (
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-12 col-md-10 ">
                <IndexCard
                  cardClass="card-bg rounded"
                  title={"Indicatori di collaborazione e riuso"}
                  data1={pm?.indexes?.response[0]?.riuso_processi + "%"}
                  data2={pm?.indexes?.response[0]?.riuso_soluzioni}
                  data3={pm?.indexes?.response[0]?.collaborazioni + "%"}
                  caption1="Riuso di processi per erogazione servizi digitali"
                  caption2="Riuso soluzioni tecniche"
                  caption3="Collaborazione con altre Amministrazioni (progetto in comune a più Amministrazioni)"
                  updateDate="23/5/2022"
                />
              </div>
            </div>
          )}
          <br></br>
          <br></br>
          {pm?.indexes?.response[0] !== undefined && (
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-12 col-md-10">
                <IndexCardBottom
                  cardClass="card-bg rounded"
                  title={"Indicatori di progresso"}
                  data1={pm?.indexes?.response[0]?.absc_1 + "%"}
                  data2={pm?.indexes?.response[0]?.absc_2}
                  data3={pm?.indexes?.response[0]?.absc_3 + "%"}
                  data4={pm?.indexes?.response[0]?.absc_4}
                  data5={pm?.indexes?.response[0]?.absc_5 + "%"}
                  data6={pm?.indexes?.response[0]?.absc_8}
                  data7={pm?.indexes?.response[0]?.absc_10 + "%"}
                  data8={pm?.indexes?.response[0]?.absc_13}
                  caption1="ABSC 1 (CSC 1): INVENTARIO DEI DISPOSITIVI AUTORIZZATI E NON AUTORIZZATI                                "
                  caption2="ABSC 2 (CSC 2): INVENTARIO DEI SOFTWARE AUTORIZZATI E NON AUTORIZZATI"
                  caption3="ABSC 3 (CSC 3): PROTEGGERE LE CONFIGURAZIONI DI HARDWARE E SOFTWARE SUI DISPOSITIVI MOBILI, LAPTOP, WORKSTATION E SERVER"
                  caption4="ABSC 4 (CSC 4): VALUTAZIONE E CORREZIONE CONTINUA DELLA VULNERABILITÀ"
                  caption5="ABSC 5 (CSC 5): USO APPROPRIATO DEI PRIVILEGI DI AMMINISTRATORE"
                  caption6="ABSC 8 (CSC 8): DIFESE CONTRO I MALWARE"
                  caption7="ABSC 10 (CSC 10): COPIE DI SICUREZZA"
                  caption8="ABSC 13 (CSC 13): PROTEZIONE DEI DATI"
                  updateDate="23/5/2022"
                />
              </div>
            </div>
          )}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="row text-left">
            <div className="col-12">
              <h4 style={{ color: "white" }}>
                Riepilogo Contratti Esecutivo AQ
              </h4>
            </div>
            <div className="col-12">
              <div className="row mx-0">
                <div
                  className="col-12 w-100"
                  style={{
                    backgroundColor: "#e6e9f2",
                    borderRadius: "5px",
                  }}
                >
                  {pm?.contracts?.contractsArray?.length > 0 && (
                    <TablePaginationExecutive
                      data={pm?.contracts?.contractsArray}
                    ></TablePaginationExecutive>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
