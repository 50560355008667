import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ProjectManagement.css";
import { useHistory } from "react-router-dom";

export const Executives = ({ id, started, ended, totalBudget, usedBudget }) => {
  let history = useHistory();

  const openExecutive = (id) => {
    history.push(`/projects/${id.replaceAll("/", "-")}`);
  };

  return (
    <div
      onClick={(e) => openExecutive(id)}
      tag="button"
      className="row m-3 p-3 pointer-executive"
      style={{ backgroundColor: "white" }}
    >
      <div className="col-12 col-md-6 col-lg-3 pb-2 pb-lg-0 text-executive">
        <h4>Contratto Esecutivo</h4>
        <b>n.{id}</b>
      </div>
      <div className="col-12 col-md-6 col-lg-3 pb-2 pb-lg-0 ">
        <div>
          <b>Iniziative avviate:</b>
        </div>
        <div class="row">
          <div className="col-6">
            <img
              alt="icon"
              className="iconDetails"
              src={process.env.PUBLIC_URL + "/assets/icons/arrow-r-blue.svg"}
            />
            <b> {started}</b>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 pb-2 pb-lg-0 ">
        <div>
          <b>Iniziative Concluse:</b>
        </div>
        <div class="row">
          <div className="col-6">
            <img
              alt="icon"
              className="iconDetails"
              src={process.env.PUBLIC_URL + "/assets/icons/done-blue.svg"}
            />
            <b> {ended}</b>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 pb-2 pb-lg-0 ">
        <div className="pb-1">
          <b>Budget</b>
        </div>
        <div className="row">
          <div className="col-6 text-left used-budget">
            <b>{usedBudget / 1000}K</b>
          </div>
          <div className="col-6 text-right">{totalBudget / 1000}K</div>
        </div>
        <ProgressBar
          //className="progress-bar"
          style={{
            borderRadius: "10px",
            height: "12px",
          }}
          min={0}
          max={totalBudget / 1000}
          now={usedBudget / 1000}
        />
      </div>
    </div>
  );
};
