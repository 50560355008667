import { BreadcrumbConsip } from "../../../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { MonitoringTable } from "./MonitoringTable";
import "../../style.css";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFilters,
  getSLA,
} from "../../../../redux/actions/projectManagement";
import TablePaginationExecutive from "../../../ProjectManagement/components/TablePagination";

export default function Sla() {
  let history = useHistory();
  const dispatch = useDispatch();
  const pm = useSelector((state) => state.projectManagement);

  useEffect(() => {
    dispatch(getFilters());
    dispatch(getSLA(filters));
  }, []);

  //filters
  const [enteType, setEnteType] = useState([]);
  const [enteTypeId, setEnteTypeId] = useState("");
  const [ente, setEnte] = useState([]);
  const [enteId, setEnteId] = useState("");
  const [contract, setContract] = useState([]);
  const [contractId, setContractId] = useState("");
  const [filters, setFilters] = useState({});

  const applyFilters = () => {
    let filtersnew = {};

    if (enteTypeId) {
      filtersnew.enteType = parseInt(enteTypeId);
    }
    if (enteId) {
      filtersnew.enti = parseInt(enteId);
    }
    if (contractId) {
      filtersnew.contrattiEsecutivi = parseInt(contractId);
    }
    setFilters(filtersnew);
    dispatch(getSLA(filtersnew));
  };

  useEffect(() => {
    const getEnteType = async () => {
      setEnteType(await pm?.filters?.filterArray?.allFilters);
    };
    getEnteType();
  }, []);

  useEffect(() => {
    const getEnte = async () => {
      enteType.map((i) => {
        if (i.ente_type_id == enteTypeId) {
          setEnte(i.Entis);
        }
      });
      if (!enteTypeId) {
        setEnte([]);
      }
    };
    setEnte([]);
    setEnteId("");
    getEnte();
    document.getElementById("ente_select").selectedIndex = 0;
    setContract([]);
    setContractId("");
  }, [enteTypeId]);

  useEffect(() => {
    const getContract = async () => {
      ente.map((i) => {
        if (i.id_ente == enteId) {
          setContract(i.ContrattiEsecutivis);
        }
      });
    };
    getContract();
    setContractId("");
    document.getElementById("contract_select").selectedIndex = 0;
  }, [enteId]);

  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[{ label: "/Monitoring/SLA", url: "/monitoring/sla" }]}
        />
      </div>

      {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
         <Alert color="warning">idToken: {user.idToken}</Alert> */}
      <div className="info-gradient pb-5">
        <div className="container" style={{ paddingBottom: "12vh" }}>
          <form>
            <div className="row g-3">
              <div className="col-md-4" style={{ textAlign: "left" }}>
                <h4>Service Level Agreement</h4>
                {/* <div className="title_description">
                                        <p>Title Description</p>
                                    </div> */}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label className="form-label">Tipologia Ente</label>
                <select
                  name="tipologia_ente"
                  className="form-control p-2"
                  onChange={(e) => setEnteTypeId(e.target.value)}
                >
                  <option value=""></option>
                  {enteType.map((resEnteType, index) => (
                    <option key={index} value={resEnteType.ente_type_id}>
                      {resEnteType.ente_type_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label">Ente</label>
                <select
                  id="ente_select"
                  className="form-control p-2"
                  name="ente"
                  onChange={(e) => setEnteId(e.target.value)}
                >
                  <option selected value=""></option>
                  {ente.map((test, index) => (
                    <option key={index} value={test.id_ente}>
                      {test.ente_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label">Contratto Esecutivo</label>
                <select
                  id="contract_select"
                  className="form-control p-2"
                  name="contratto"
                  onChange={(e) => setContractId(e.target.value)}
                >
                  <option selected value=""></option>
                  {contract.map((test2, index) => (
                    <option key={index} value={test2.id_contratto}>
                      {" "}
                      {test2.contratto_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <button
                type="button"
                className="btn btn-primary mt-1"
                style={{ paddingRight: "1vw", paddingLeft: "1vw" }}
                onClick={applyFilters}
              >
                Applica Filtri
              </button>
            </div>
          </form>
          {/* <br></br> */}
          {pm?.sla?.response[0] !== undefined && (
            <div className="table_background">
              <MonitoringTable data={pm?.sla?.response[0]} />
            </div>
          )}
          <br></br>
          <br></br>
          <div className="row text-left">
            <div className="col-12">
              <h4 style={{ color: "white" }}>
                Riepilogo Contratti Esecutivo AQ
              </h4>
            </div>
            <div className="col-12">
              <div className="row mx-0">
                <div
                  className="col-12 w-100"
                  style={{
                    backgroundColor: "#e6e9f2",
                    borderRadius: "5px",
                  }}
                >
                  {pm?.contracts?.contractsArray?.length > 0 && (
                    <TablePaginationExecutive
                      data={pm?.contracts?.contractsArray}
                    ></TablePaginationExecutive>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
