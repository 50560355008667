import { ConsoleLogger } from "@aws-amplify/core";
import * as Yup from "yup";


export function getYupObjByField(fieldName) {
  // Regex
  const onlyCharRegExp = "^\\D+$";
  const numberRegExp = "^[0-9]*$";
  const checkRegExp = "^[^\\s][A-zÀ-ú0-9., '/-]+$";

  switch (fieldName) {
    case "contractCode":
      return Yup.string()
        .required(`Campo Obbligatorio`)
        .matches(
          numberRegExp,
          `Il Codice inserito contiene Caratteri non permessi`
        );
    case "file":
    case "datetime":
    case "section":
      return Yup.string().required(`Campo Obbligatorio`);
    default:
      return;
  }
}

export function getSchemaObject(fields) {
  if (fields?.length) {
    const validationObject = {};
    fields.forEach((field) => {
      validationObject[field] = getYupObjByField(field);
    });
    return Yup.object().shape(validationObject);
  } else {
    return null;
  }
}

export function parseContractsDetails(response) {
  let budget = 0;
  let usedBudget = 0;
  let startedContract = 0;
  let endedContract = 0;

  //cicle all the contract
  response?.map((elem, index) => {
    //single dates related to the initiatives
    let usedBudgetSingle = 0;
    let startedInitiatives = 0;
    let endedInitiatives = 0;

    //cicle all the initiatives for 1 contract
    elem?.Iniziatives?.map((initiative, index) => {
      //upload used budget of single contract
      usedBudgetSingle = usedBudgetSingle + initiative.expense;
      //upload counter initiatives status
      /*
                  if(initiative.IniziativeStatus==="AVVIATA")
                  startedInitiatives++
                  else if (initiative.IniziativeStatus==="CONCLUSA")
                  endedInitiatives++
                  */
    });

    //upload for this contract the used Budget, startedInitiatives and endedInitiatives
    elem.usedBudget = usedBudgetSingle;
    elem.startedInitiatives = startedInitiatives;
    elem.endedInitiatives = endedInitiatives;

    //upload total Budget, usedBudget startedContract and endedContract
    budget = budget + elem.budget;
    usedBudget = usedBudget + usedBudgetSingle;
    startedContract =
      startedInitiatives > 0 ? startedContract + 1 : startedContract;
    endedContract =
      endedContract === elem?.Iniziatives?.length
        ? endedContract + 1
        : endedContract;
  });
  let newData = {
    budget: budget,
    usedBudget: usedBudget,
    startedContract: startedContract,
    endedContract: endedContract,
    contractsArray: response,
  };
  return newData;
}

export function parseFilters(response) {

  let newData = {
    filterArray: response,
    statusArray: response.initiativeStatus,
  }
  return newData;
}

export function parseMonitoringDetails(response) {

  let newData = {
    response,
  }
  return newData;


}

export function parseSLA(response) {

  if (response.length != 0) {
  
    if (response[0].risorse_inadeguate_sum == null) response[0].risorse_inadeguate_sum = 0;
    if (response[0].impegni_non_adempiuti_sum == null) response[0].impegni_non_adempiuti_sum = 0;
    if (response[0].giorni_ritardo_sum == null) response[0].giorni_ritardo_sum = 0;
    if (response[0].risorse_non_rispondenti_requisiti_sum == null) response[0].risorse_non_rispondenti_requisiti_sum = 0;
    if (response[0].risorse_non_certificate_sum == null) response[0].risorse_non_certificate_sum = 0;
    if (response[0].risorse_sostituite_fornitore_sum == null) response[0].risorse_sostituite_fornitore_sum = 0;
    if (response[0].obbligazioni_non_adempiute_sum == null) response[0].obbligazioni_non_adempiute_sum = 0;
    if (response[0].rispetto_scadenza_sum == null) response[0].rispetto_scadenza_sum = 0;
    if (response[0].rispetto_standard_sum == null) response[0].rispetto_standard_sum = 0;
    if (response[0].tempo_attivazione_sum == null) response[0].tempo_attivazione_sum = 0;
    if (response[0].test_negativi_collaudo_sum == null) response[0].test_negativi_collaudo_sum = 0;
    if (response[0].giorni_sospensione_collaudo_sum == null) response[0].giorni_sospensione_collaudo_sum = 0;
  }
  
  let newData = {
    response
  }
  return newData;
}

export function parseDigIndexes(response) {

  let newData = {
    response
  }
  return newData;
}

export function parseTipologiaBudget(response) {

  let expenses = [];
  let budgets = [];
  let types = [];

  for (let i = 0; i < response.length; i++) {
    expenses[i] = response[i].expense;
    budgets[i] = response[i].budget;
    types[i] = response[i].iniziativa_type;
  }

  let newData = {
    response,
    expenses: expenses,
    budgets: budgets,
    types: types
  }
  return newData;
}

export function parseEcosistemBudget(response) {

  let expenses = [];
  let budgets = [];
  let types = [];

  for (let i = 0; i < response.length; i++) {
    expenses[i] = response[i].expense;
    budgets[i] = response[i].budget;
    types[i] = response[i].ente_type;
  }

  let newData = {
    response,
    expenses: expenses,
    budgets: budgets,
    types: types
  }
  return newData;
}



