import {
  Card,
  CardCategory,
  CardReadMore,
  CardSignature,
  Icon,
} from "design-react-kit";
import React from "react";
import { CardBody, CardText, CardTitle } from "reactstrap";
import { App } from "./barchart";

export const BarCard = ({ title, url, cardClass, dataBar }) => {
  console.log("this is the data in the barcard", dataBar);
  return (
    <Card className={cardClass} noWrapper={false} tag="div">
      <CardBody tag="div">
        <CardTitle className="big-heading" tag="h6">
          {title}
        </CardTitle>
        <canvas id="barChart" height="20"></canvas>
        <App data={dataBar} />
        <CardReadMore
          iconName="it-arrow-right"
          tag="a"
          text="Vedi i dettagli"
          href={url}
        />
      </CardBody>
    </Card>
  );
};
