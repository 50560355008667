import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const data = {
  //options don't work here
  options: {
    scales: {
      r: {
        max: 5,
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
  labels: [
    "Dimensione 1",
    "Dimensione 2",
    "Dimensione 3",
    "Dimensione 4",
    "Dimensione 5",
    "Dimensione 6",
    "Dimensione 7",
  ],
  datasets: [
    {
      label: "Rilevazione 03/05/2022",
      data: [5, 5, 3, 4, 5, 4, 5],
      fill: false,
      backgroundColor: "rgb(0,195,255)",
      borderColor: "rgb(0,195,255)",
      pointBackgroundColor: "rgb(0,195,255)",
      pointBorderColor: "#00c3ff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(255, 99, 132)",
    },

    //This is to calibrate the scale of the chart for now
    {
      label: "min = 0, max = 5",
      data: [0.01],
      fill: false,
      backgroundColor: "rgb(255, 255, 255)",
      borderColor: "rgb(255, 255, 255)",
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "#ffffff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(255, 255, 255)",
    },
  ],
};

export function App() {
  return <Radar data={data} />;
}
